@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Product Sans'; /* Replace with your desired font name */
  src: url('./assets/fonts/Product\ Sans\ Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: 'Product Sans', sans-serif; 
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.pt-regular {
  font-family: 'Product Sans', sans-serif; 
  font-weight: 400;
  font-style: normal;
}

.pt-medium {
  font-family: 'Product Sans', sans-serif; 
  font-weight: 500;
  font-style: normal;
}

.pt-semibold {
  font-family: 'Product Sans', sans-serif; 
  font-weight: 600;
  font-style: normal;
}

.pt-bold {
  font-family: 'Product Sans', sans-serif; 
  font-weight: 700;
  font-style: normal;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}